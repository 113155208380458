import { DatePipe } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { setError, setLoaded, setLoading, withCallState } from '@config';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withHooks,
  withMethods,
} from '@ngrx/signals';
import {
  addEntities,
  setAllEntities,
  withEntities,
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import {
  NotificationMessage,
  NotificationService,
} from '@paldesk/shared-lib/data-access/notifications-generated';
import { tap } from 'rxjs';
import { Alert, NotificationCard, PastIncidentsDto } from '../models';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';

@Injectable({
  providedIn: 'root',
})
export class NotificationsStore extends signalStore(
  { protectedState: false },
  withCallState(),
  withEntities({
    entity: type<NotificationCard>(),
    collection: 'notifications',
  }),
  withEntities({
    entity: type<NotificationCard>(),
    collection: 'notificationsHistory',
  }),
  withComputed((state) => ({
    notifications: state.notificationsEntities,
    notificationsHistory: state.notificationsHistoryEntities,
  })),
  withMethods((state) => {
    const notificationService = inject(NotificationService);
    const translateService = inject(TranslateService);
    const datePipe = inject(DatePipe);

    function getAlert(message: NotificationMessage): Alert {
      const informationLevel = 'INFORMATION'; //Maintenance level

      if (message.level === informationLevel) {
        return {
          text: message.text,
          title: null,
          downtime: null,
        };
      } else {
        let dateText;
        let downtimeText: string | null = null;

        dateText = `${translateService.instant(
          'status_paldesk.issue.started',
        )}`;
        if (message.start_date) {
          dateText += ` ${datePipe.transform(
            new Date(message.start_date),
            'short',
          )}`;
        } else {
          dateText += `${translateService.instant(
            'status_paldesk.issue.under_investigation',
          )}`;
        }
        dateText +=
          ' - ' +
          `${translateService.instant('status_paldesk.resolved.title')}`;
        if (
          message.start_date &&
          message.expiration_date &&
          new Date(message.expiration_date) <= new Date()
        ) {
          dateText += `${datePipe.transform(
            new Date(message.expiration_date),
            'short',
          )}`;
          downtimeText = calculateDownTimeText(
            new Date(message.start_date),
            new Date(message.expiration_date),
          );
        } else {
          dateText += `${translateService.instant(
            'status_paldesk.issue.not_resolved',
          )}`;
        }
        return {
          title: dateText,
          downtime: downtimeText !== '' ? downtimeText : null,
          text: message.text,
        };
      }
    }

    function calculateDownTimeText(startDate: Date, expirationDate: Date) {
      const diffMilliseconds = expirationDate.getTime() - startDate.getTime();
      const diffMinutes = Math.floor(diffMilliseconds / 1000 / 60);
      const downtimeText = `${translateService.instant(
        'status_paldesk.issue.downtime',
      )}`;

      const result: string[] = [];
      result.push(downtimeText);
      if (diffMinutes >= 1440) {
        const days = Math.floor(diffMinutes / 1440);
        result.push(
          `${days} ${translateService.instant(
            'status_paldesk.granularity_filter.days',
          )}`,
        );
      }

      const hours = Math.floor((diffMinutes % 1440) / 60);
      if (hours > 0) {
        result.push(
          `${hours} ${translateService.instant(
            'status_paldesk.granularity_filter.hours',
          )}`,
        );
      }

      const minutes = diffMinutes % 60;
      if (minutes > 0) {
        result.push(
          `${minutes} ${translateService.instant(
            'status_paldesk.granularity_filter.minutes',
          )}`,
        );
      }
      return result.join(' ');
    }

    function filterHistoryNotifications(messages: any[], startDate, endDate) {
      let filteredMessages = Array.isArray(messages) ? messages : [];
      if (startDate && endDate) {
        filteredMessages = filteredMessages.filter((message) =>
          filterNotifications(message, {
            startDate: startDate,
            endDate: endDate,
          }),
        );
      }
      return filteredMessages;
    }

    function filterNotifications(notification: NotificationMessage, filter) {
      const startDate = notification.start_date
        ? new Date(notification.start_date)
        : null;
      const expirationDate = notification.expiration_date
        ? new Date(notification.expiration_date)
        : null;

      const filterStartTime = filter.startDate.getTime();
      const filterEndTime = getEndOfTheDay(filter.endDate);

      if (!startDate && !expirationDate) {
        // When both start and end date are null, show on current incidents
        return true;
      }

      if (!startDate) {
        // When only start date is null, show on current or past incidents depending on end date
        return (
          expirationDate &&
          expirationDate.getTime() >= filterStartTime &&
          expirationDate.getTime() <= filterEndTime
        );
      }

      if (!expirationDate) {
        // When only end date is null, show on current incidents or not shown at all depending on start date
        return startDate.getTime() <= filterEndTime;
      }

      // When both dates are set, show on current or past incidents depending on dates
      return DateUtils.isInRange(
        startDate.getTime(),
        expirationDate.getTime(),
        filterStartTime,
        filterEndTime,
      );
    }

    function getEndOfTheDay(date: Date): number {
      const endOfDay = new Date(date);
      endOfDay.setHours(23, 59, 59, 999);
      return endOfDay.getTime();
    }

    function createNotificationCards(
      messages: NotificationMessage[],
    ): NotificationCard[] {
      return messages.map((notification) => ({
        id: notification.id,
        level: notification.level ?? null,
        title: notification.title ?? null,
        link: notification.link ?? null,
        content: getAlert(notification),
        applicationCategories: notification.application_categories ?? [],
      }));
    }

    return {
      getPublicNotifications: () => {
        patchState(state, setLoading());
        notificationService
          .getPublicNotifications()
          .pipe(takeUntilDestroyed())
          .subscribe({
            next: (data) => {
              const messages = createNotificationCards(data?.messages || []);
              patchState(
                state,
                addEntities(messages, { collection: 'notifications' }),
              );
              patchState(state, setLoaded());
            },
            error: (error) => {
              patchState(state, setError(error.message));
            },
          });
      },
      setError: (err) => {
        patchState(state, setError(err.message));
      },
      getPublicNotificationsHistory: rxMethod<PastIncidentsDto>((data$) =>
        data$.pipe(
          tap((data) => {
            patchState(state, setLoading());
            const messages = createNotificationCards(
              filterHistoryNotifications(
                data.messages,
                data.startDate,
                data.endDate,
              ),
            );
            patchState(
              state,
              setAllEntities(messages, {
                collection: 'notificationsHistory',
              }),
            );
            patchState(state, setLoaded());
          }),
        ),
      ),
      getAlert,
      calculateDownTimeText,
      filterHistoryNotifications,
      filterNotifications,
    };
  }),
  withHooks({
    onInit(state) {
      state.getPublicNotifications();
    },
  }),
) {}
